import '@fancyapps/fancybox';
import './plugins/inView';
import Swiper, {
    Navigation, Pagination, Controller, Lazy
} from 'swiper';

// import mapBox from './inc/hmMaps';
// import smoothScroll from './inc/smoothScroll';
import dataStore from './inc/dataStore';
import {
    herrlichesMenu, mobileSubmenu, hmNavInit, mainNavigation
} from './inc/header';
import browserDetection from './inc/browserDetection';

Swiper.use(
    [Navigation, Pagination, Controller, Lazy]
);

const LazyLoad = require('vanilla-lazyload');

window.jQuery = jQuery;
window.$ = jQuery;

/* Author: herrlich media */
$(document).ready(() => {
    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Ready Inits +++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    /* browser/touch detection ++++++++++++++++++++++++++++++++++++++++++*/
    browserDetection();

    /* Herrlich Media Nav Init ++++++++++++++++++++++++++++++++++++++++++*/
    hmNavInit();
    mainNavigation();

    // Smooth Mousewheel Scrolling for Chrome & Safari
    // smoothScroll();

    $('.gform_wrapper form').submit((e) => {
        $(e.currentTarget).find('.gform_footer').addClass('loading');
    });

    $('.fadeWrap').each((a, el) => {
        const children = $(el).find('.fadeItem');
        $(el).one('inview', (event, visible) => {
            if (visible === true) {
                children.each((i, item) => {
                    setTimeout(() => {
                        $(item).addClass('vis');
                    }, 150 * i);
                });
            }
        });
    });

    $('#serviceList li a').on('mouseenter', (e) => {
        if (dataStore.serviceSwiper !== false) {
            const index = $(e.currentTarget).parent().index();
            dataStore.serviceSwiper.slideTo(index, 300, true);
        }
    });

    if ($('#projectsTextWrapper').length) {
        textAccordionResize(dataStore.projectsHeights, $('#projectsTextWrapper ul'));
    }

    // ---------------------------------- services cat ---------------------------------- //

    if ($('#serviceTitlesList').length) {
        const servicesHeight = $('#serviceTitlesList').height();
        $('#servicesRow').css('min-height', servicesHeight);
    }

    $('#serviceTitlesList li a').on('click', (e) => {
        if (dataStore.docWidth >= 768) {
            e.preventDefault();

            const target = $(e.currentTarget);
            const current = target.parent();
            const slug = target.data('slug');
            const oldCurrent = $('#serviceTitlesList, #serviceContentsList, #serviceImagesList').find('li.current');
            const index = current.index();

            let url = `${window.location.origin}/leistungen/${slug}`;

            if (window.location.pathname === '/leistungen/') {
                url = slug;
            }

            window.history.pushState('', '', url);

            oldCurrent.removeClass('current');
            current.addClass('current');
            $('#serviceContentsList li').eq(index).addClass('current');
            $('#serviceImagesList li').eq(index).addClass('current');

            $('#serviceContentsList').css({
                'min-height': `${dataStore.serciveHeights[index]}px`
            });
        }
    });

    // servicesResizes();
    if (dataStore.globalIds.services === '4') {
        textAccordionResize(dataStore.serciveHeights, $('#serviceContentsList'));
    }

    // ---------------------------------- TIMELINE ---------------------------------- //
    if (dataStore.isStepWrap) {
        textAccordionResize(dataStore.stepContentsHeights, $('#stepContents'));

        if (dataStore.touch === false) {
            $(document).on('mousemove', (e) => {
                if (dataStore.stepWidthDiff < 0) {
                    stepResize();
                }

                dataStore.stepMove = (180 + dataStore.docWidth - dataStore.stepWrapWidth - e.pageX)
                * (dataStore.stepWidthDiff / dataStore.stepWrapWidth);

                if (dataStore.isIe === false) {
                    dataStore.stepList.css({
                        transform: `translate( ${dataStore.stepMove}px,0)`
                    });
                }
            });
        } else {
            $('.timelineBtn').on('click', (e) => {
                e.preventDefault();

                const thisBtn = $(e.currentTarget);
                const current = $('.stepList li.current');
                const i = current.index();
                const count = $('.stepList li').length;

                let targetI;

                if (thisBtn.hasClass('prev')) {
                    targetI = i - 1;
                } else {
                    targetI = i + 1;
                }

                const target = $('.stepList li').eq(targetI);

                if (target.length) {
                    target.trigger('click');

                    if (dataStore.stepWidthDiff > 0) {
                        const width = target.width();
                        let trans;

                        if (targetI === 0) {
                            trans = (dataStore.stepWidthDiff / count) * (count - targetI);
                        } else if (targetI + 1 === count) {
                            trans = (dataStore.stepWidthDiff / count) * (count - targetI) - width;
                        } else {
                            trans = (dataStore.stepWidthDiff / count) * (count - targetI) - (width / 2);
                        }

                        dataStore.stepList.css({
                            transform: `translate( ${trans}px,0)`
                        });
                    }

                    const targetN = $('.stepList li').eq(targetI + 1);

                    if (targetI - 1 === -1) {
                        $('.timelineBtn.prev').addClass('disabled');
                    } else {
                        $('.timelineBtn.prev').removeClass('disabled');
                    }

                    if (targetN.length === 0) {
                        $('.timelineBtn.next').addClass('disabled');
                    } else {
                        $('.timelineBtn.next').removeClass('disabled');
                    }
                }
            });
        }

        $('.stepList li').on('click', (e) => {
            e.preventDefault();

            const thisLi = $(e.currentTarget);
            const i = thisLi.index();
            const count = $('.stepList li').length;

            $('.stepList li.current').removeClass('current');
            thisLi.addClass('current');

            $('#stepContents li.current').removeClass('current');
            $('#stepContents li').eq(i).addClass('current');

            $('#stepImages li.current').removeClass('current');
            $('#stepImages li').eq(i).addClass('current');

            $('#stepContents').css({
                'min-height': `${dataStore.stepContentsHeights[i]}px`
            });

            if (dataStore.touch === true) {
                if (dataStore.stepWidthDiff > 0) {
                    const width = thisLi.width();
                    let trans;

                    if (i === 0) {
                        trans = (dataStore.stepWidthDiff / count) * (count - i);
                    } else if (i + 1 === count) {
                        trans = (dataStore.stepWidthDiff / count) * (count - i) - width;
                    } else {
                        trans = (dataStore.stepWidthDiff / count) * (count - i) - (width / 2);
                    }

                    dataStore.stepList.css({
                        transform: `translate( ${trans}px,0)`
                    });
                }

                const targetN = $('.stepList li').eq(i + 1);

                if (i - 1 === -1) {
                    $('.timelineBtn.prev').addClass('disabled');
                } else {
                    $('.timelineBtn.prev').removeClass('disabled');
                }

                if (targetN.length === 0) {
                    $('.timelineBtn.next').addClass('disabled');
                } else {
                    $('.timelineBtn.next').removeClass('disabled');
                }
            }
        });
    }

    // ---------------------------------- job Accoreon ---------------------------------- //
    const accordeonHeader = $('.accordeon-header');
    if (accordeonHeader.length) {
        accordeonHeader.on('click', (e) => {
            const target = $(e.currentTarget);
            const parent = target.parent();

            accordeonHeader.parent().not(parent).removeClass('active');
            parent.toggleClass('active');
        });
    }

    const jobApplyBtn = $('.job-apply');
    const jobForm = $('#jobs-form');
    if (jobApplyBtn.length) {
        jobApplyBtn.on('click', (e) => {
            e.preventDefault();
            jobForm.addClass('active');

            $('html, body').animate({
                scrollTop: jobForm.offset().top - 100
            }, 500);

            jobForm.find('.form-job-title input').val($(e.currentTarget).data('title'));
        });
    }

    // Jquery Smooth Scroll to anchor
    $('a[href^="#"]').on('click', (e) => {
        e.preventDefault();

        const target = e.target.hash;
        const $target = $(target);
        if ($target.length) {
            $('html, body').stop().animate({
                scrollTop: $target.offset().top - 250
            }, 500, 'swing');
        }
    });

    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Plugins +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    /* lazy load  ++++++++++++++++++++++++++++++++++++++++++*/
    const lazyLoadInstance = new LazyLoad({
        elements_selector: '.lazy'
    });

    lazyLoadInstance.update();

    // FANCYBOX
    $('[data-fancybox]').fancybox({
        afterLoad() {
            dataStore.smoothDisabled = true;
        },
        beforeClose() {
            dataStore.smoothDisabled = false;
        }
    });

    // $('._brlbs-btn').on('click', () => {
    //     dataStore.endOfResize = setTimeout(() => {
    //         if (window.BorlabsCookie.checkCookieConsent('mapbox')) {
    //             mapBox();
    //         }
    //     }, 250);
    // });

    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Scroll ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    $(window).scroll(() => {
        dataStore.docScroll = $(window).scrollTop();

        dataStore.scrollHeader.toggleClass('scrolled', dataStore.docScroll >= dataStore.scrollHeaderTop);

        if (dataStore.isStepWrap) {
            dataStore.stepWrap.trigger('mousemove');
        }
    });
}); /* ready end ++++++++++++++++++++++++++++++++++++++++++*/

$(window).resize(() => {
    dataStore.docWidth = $(document).width();
    dataStore.docHeight = $(document).height();
    dataStore.winHeight = $(window).height();

    // servicesResizes();
    if (dataStore.globalIds.services === '4') {
        textAccordionResize(dataStore.serciveHeights, $('#serviceContentsList'));
    }

    if ($('#projectsTextWrapper').length) {
        textAccordionResize(dataStore.projectsHeights, $('#projectsTextWrapper ul'));
    }

    $(window).trigger('scroll');

    stepResize();

    clearTimeout(dataStore.endOfResize);
    dataStore.endOfResize = setTimeout(() => {
        afterResize();
    }, 250);
});

function afterResize() {
    mobileSubmenu();
    herrlichesMenu.destroy();
    herrlichesMenu.init();
    mainNavigation();
}

function stepResize() {
    if (dataStore.isStepWrap) {
        textAccordionResize(dataStore.stepContentsHeights, $('#stepContents'));
        dataStore.stepWrapWidth = dataStore.stepWrap.width();
        dataStore.stepListWidth = dataStore.stepList.outerWidth();
        dataStore.stepWidthDiff = dataStore.stepListWidth - dataStore.stepWrapWidth;
    }
}

$(window).on('load', () => {
    stepResize();

    new Swiper('.bigHeader .swiper-container', {
        // Optional parameters
        loop: true,
        // lazy: true,
        lazy: {
            //  tell swiper to load images before they appear
            loadPrevNext: true,
            // amount of images to load
            loadPrevNextAmount: 2,
        },
        navigation: {
            nextEl: '.bigHeader .swiper-button-next',
            prevEl: '.bigHeader .swiper-button-prev'
        }
    });

    if ($('#servicesTeaser').length) {
        dataStore.serviceSwiper = new Swiper('.serviceSwiper', {
            slidesPerView: 'auto',
            // slidesPerView: 1,
            loop: true,
            // centeredSlides: true,
            lazy: {
                loadPrevNext: true,            // amount of images to load
                loadPrevNextAmount: 2,
            },
            navigation: {
                nextEl: '#servicesTeaser .swiper-button-next',
                prevEl: '#servicesTeaser .swiper-button-prev',
            },
            // breakpoints: {
            //     640: {
            //         slidesPerView: 2
            //     },
            //     768: {
            //         slidesPerView: 4
            //     },
            //     1024: {
            //         slidesPerView: 5
            //     }
            // }
        });
    }

    if ($('#projectsMainSlider').length) {
        const swiper1 = new Swiper('#projectsMainSlider', {
            slidesPerView: 'auto',
            loop: true,
            centeredSlides: false,
            lazy: {
                loadPrevNext: true,
                loadPrevNextAmount: 2,
            },
            navigation: {
                nextEl: '#projectsTeaser .swiper-button-next',
                prevEl: '#projectsTeaser .swiper-button-prev',
            },
        });

        const swiper2 = new Swiper('#projectsSideSlider', {
            slidesPerView: 'auto',
            loop: true,
            allowTouchMove: false,
            centeredSlides: false
        });

        swiper1.controller.control = swiper2;
        swiper2.controller.control = swiper1;

        swiper1.on('slideChange', () => {
            $('#projectsTextWrapper li.current').removeClass('current');
            $(`#projectsTextWrapper li[data-item=${swiper1.realIndex}]`).addClass('current');

            $('#projectsTextWrapper ul').css({
                'min-height': `${dataStore.projectsHeights[swiper1.realIndex + 1]}px`
            });
        });
    }

    if ($('#projectsThumbSlider').length) {
        const galleryThumbs = new Swiper('.gallery-thumbs', {
            spaceBetween: 10,
            slidesPerView: 4,
            loop: true,
            loopedSlides: 3, //looped slides should be the same
            centeredSlides: true,
            allowTouchMove: false
        });

        const thumbSlider = new Swiper('#projectsThumbSlider', {
            loop: true,
            loopedSlides: 3, //looped slides should be the same
            // thumbs: {
            //     swiper: galleryThumbs,
            // },
            navigation: {
                nextEl: '#categoryprojects .swiper-button-next',
                prevEl: '#categoryprojects .swiper-button-prev',
            },
        });

        // galleryThumbs.controller.control = thumbSlider;
        thumbSlider.controller.control = galleryThumbs;

        thumbSlider.on('slideChange', () => {
            const newCurrent = $(`#projectsTextWrapper li[data-item=${thumbSlider.realIndex}]`);
            const slug = newCurrent.data('slug');
            let url = `${window.location.origin}/projekte/${slug}`;

            if (window.location.pathname === '/projekte/') {
                url = slug;
            }

            window.history.pushState('', '', url);

            $('#projectsTextWrapper li.current').removeClass('current');
            newCurrent.addClass('current');

            $('#projectsTextWrapper ul').css({
                'min-height': `${dataStore.projectsHeights[thumbSlider.realIndex + 1]}px`
            });
        });

        $('.gallery-thumbs .projectsSlide').on('click', (e) => {
            e.preventDefault();
            const slide = $(e.currentTarget).parent();
            const index = slide.data('swiper-slide-index');
            thumbSlider.slideTo(index);
        });

        // go to current slide on single
        const current = $('#projectsThumbSlider').data('current');

        if (current !== 0) {
            const currentSlide = $(`#projectsThumbSlider .projectsSlide[data-id=${current}]`).parent();
            const index = currentSlide.data('swiper-slide-index');
            thumbSlider.slideTo(index);
        }
    }

    if ($('.iconSlider-slides').length) {
        const iconSliderSizer = $('.iconSlider-slides--sizer').width();

        $('.iconSlider-slides .swiper-slide').css({
            width: iconSliderSizer
        });

        new Swiper('.iconSlider-slides', {
            loop: false,
            allowTouchMove: true,
            spaceBetween: 32,
            slidesPerView: 'auto',
            navigation: {
                nextEl: '.iconSlider-slides .swiper-button-next',
                prevEl: '.iconSlider-slides .swiper-button-prev'
            }
        });
    }

    /**** Map Box ***/
    // if ($('#map').length) {
    //     if (window.BorlabsCookie.checkCookieConsent('mapbox')) {
    //         mapBox();
    //     }
    //     dataStore.endOfResize = setTimeout(() => {
    //         console.log(window.BorlabsCookie.checkCookieConsent('mapbox'));
    //         if (window.BorlabsCookie.checkCookieConsent('mapbox')) {
    //             mapBox();
    //         }
    //     }, 500);
    // }
});

// function servicesResizes() {
//     if (dataStore.globalIds.services === '4') {
//         $('#serviceContentsList li').each((item, el) => {
//             const serviceItemHeight = $(el).height();
//             dataStore.serciveHeights.push(serviceItemHeight);
//         });

//         const i = $('#serviceContentsList li.current').index();

//         $('#serviceContentsList').css({
//             'min-height': `${dataStore.serciveHeights[i]}px`
//         });
//     }
// }

function textAccordionResize(array, wrap) {
    wrap.find('li').each((item, el) => {
        const itemHeight = $(el).height();
        array.push(itemHeight);
    });

    const i = wrap.find('li.current').index();

    wrap.css({
        'min-height': `${array[i]}px`
    });
}
