class DataStore {
    constructor() {
        /* scrolling ++++++++++++++++++++++++++++++++++++++++++*/
        this.docScroll = '';
        this.win = $(window);

        /* window ++++++++++++++++++++++++++++++++++++++++++*/

        this.docWidth = $(document).width();
        this.docHeight = $(document).height();
        this.winHeight = $(window).height();

        /* non editable ++++++++++++++++++++++++++++++++++++++++++*/
        this.endOfResize = false;

        /* browser ++++++++++++++++++++++++++++++++++++++++++*/

        this.isIe = false;
        this.isFirefox = false;
        this.isSafari = false;
        this.isNoIe = false;
        this.touch = false;
        this.touchBreakPoint = 991;

        /* ids ++++++++++++++++++++++++++++++++++++++++++*/

        this.globalIds = JSON.parse(window.wp_urls.globalIds);

        /* fancybox ++++++++++++++++++++++++++++++++++++++++++*/
        this.fancyBoxOnceOpened = false;

        /* smoothscrolling ++++++++++++++++++++++++++++++++++++++++++*/
        this.scrollEventListener = 'wheel';
        this.smoothDisabled = false;

        /* masonry ++++++++++++++++++++++++++++++++++++++++++*/
        this.grid = '';

        /* Map ++++++++++++++++++++++++++++++++++++++++++*/
        this.mapMarkers = [];
        this.map = '';

        this.serviceSwiper = false;

        /* Header ++++++++++++++++++++++++++++++++++++++++++*/
        this.scrollHeader = $('.header');
        this.scrollHeaderTop = 50;

        /* Navi Hover ++++++++++++++++++++++++++++++++++++++++++*/
        this.scrollNaviCurrentIndex = 0;
        this.line = $('#line');
        this.animationActive = false;

        /* Timeline ++++++++++++++++++++++++++++++++++++++++++*/
        this.stepWrap = $('.stepWrap');
        this.isStepWrap = this.stepWrap.length;
        this.stepWrapWidth = this.stepWrap.width();
        this.stepList = $('#timeLine');
        this.stepListWidth = this.stepList.outerWidth();
        this.stepWidthDiff = this.stepListWidth - this.stepWrapWidth;
        this.stepMove = 0;
        this.stepContentsHeights = [];

        /* service cat ++++++++++++++++++++++++++++++++++++++++++*/
        this.serciveHeights = [];

        /* projects ++++++++++++++++++++++++++++++++++++++++++*/
        this.projectsHeights = [];
    }
}

export default (new DataStore());
